import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { withPageWrapper } from '@components/PageWrappers/CorePageWrapper';
import { SplatIcon } from '@components/Icons';
import ImageService from '@services/ImageService';
import TopCitiesStatic from '@utilities/static/TopCitiesStatic';

const ErrorPage404 = () => (
  <div className="mw--1024 m-horizontal--auto m-bottom--xx-large p-vertical--xxx-large p-horizontal--large p-horizontal--x-large-gt-xs layout-gt-xs-row layout-align-space-between-start">
    <div className="relative mw--432 ta-center--lt-sm p-around--x-large p-vertical--xxx-large-gt-sm p-horizontal--xxxx-large-gt-sm flex-order-gt-xs-2">
      <SplatIcon className="w--100p h--auto absolute a--top a--left light-yellow" />
      <Image alt="Wilderness explorer checking his map" height={300} priority src={ImageService.explorerCheckingMap} width={300} />
    </div>
    <div className="mw--432 m-top--x-large-lt-sm m-right--x-large-gt-xs">
      <h1>Lost, eh?</h1>
      <p className="m-top--small">We can’t find the page you’re looking for.</p>
      <h3 className="m-top--xx-large">Popular links</h3>
      <div className="m-top--small layout-row layout-align-start-start layout-wrap">
        <div className="m-top--small flex-50">
          <Link href="/">
            <a>Home</a>
          </Link>
        </div>
        <div className="m-top--small flex-50">
          <Link href="/host">
            <a>Become a Host</a>
          </Link>
        </div>
        <div className="m-top--small flex-50">
          <Link href="/about">
            <a>About Kopa</a>
          </Link>
        </div>
        <div className="m-top--small flex-50">
          <a href="/contact" target="_self">Contact Us</a>
        </div>
      </div>
      <h3 className="m-top--xx-large">Top cities to find rentals</h3>
      <div className="m-top--small layout-row layout-align-start-start layout-wrap">
        { TopCitiesStatic.map((city, index) => (
          <div className="m-top--small flex-50" key={`top-city-${index}`}>
            <Link href={`/c/${city.state.slug}/${city.slug}`}>
              <a>{ city.name }{ city.state.name ? ', ' + city.state.name : '' }</a>
            </Link>
          </div>
        ))}
      </div>
      <p className="m-top--xx-large">Error code: 404</p>
    </div>
  </div>
);

export default withPageWrapper(ErrorPage404);
